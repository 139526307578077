body,
html {
  height: 100%;

  @media screen and (max-width: 380px) {
    font-size: 10px;
  }
  @media screen and (max-width: 150px) {
    font-size: 6px;
  }
}

#root {
  display: flex;
  justify-content: center;
}

.game-board-container {
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}
.game-board-column {
  display: flex;
  justify-content: center;
  padding: 2em 5px;
}

.game-numbers-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
  }
}

.generated-number-container {
  min-height: 5em;
  padding-top: 1em;
}

.random-numbers-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  text-align: center;
}

.random-button {
  background-color: #97b2ce;
  color: white;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
  min-width: 12em;

  transition: background-color 0.3s, transform 0.2s;

  @media screen and (max-width: 768px) {
    padding: 10px 0;
  }

  @media screen and (max-width: 360px) {
    font-size: 4px;
  }
}

.random-button.disabled {
  background-color: #d9d9d9;
  cursor: not-allowed;
}
.random-button.disabled:hover {
  background-color: #d9d9d9;
  cursor: not-allowed;
}
.random-button.disabled:active {
  transform: none;
}

.random-button:hover {
  background-color: #80a0c2;
}

.random-button:active {
  transform: scale(0.8);
}

.random-button:focus {
  outline: none;
}

.box-container {
  padding: 0.2em 1em;
  border-radius: 5px;
  min-width: 6em;
  background-color: #dfeeff;
  border: 2px solid #93afcc;
  cursor: pointer;
  font-size: 12px;
}

.box-container.disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
  border: 2px solid #d9d9d9;
}

.small-text {
  font-size: 12px;
  font-weight: 500;
}

.explanation-title {
  padding-bottom: 1em;
}

.game-over-text {
  color: red;
  font-size: 26px;
  font-weight: 600;
}

.high-score-text {
  font-weight: 600;
}

.current-score-text {
  font-weight: 200;
}

.podium-container {
  padding-top: 2em;
  width: 100%;
}

.podium-container ul {
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}

.box-id-text {
  font-weight: 700;
}
